/* eslint no-console: 0 */
import TurbolinksAdapter from "vue-turbolinks";
import axios from "axios/index";
import Vue from "vue/dist/vue.esm";
import Multiselect from "vue-multiselect/src";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.use(TurbolinksAdapter);
Vue.component("vue-multiselect", Multiselect);
Vue.component("v-select", vSelect);
Vue.config.devtools = true;

document.addEventListener("turbolinks:load", () => {
    if ($("#customerSelect").length > 0) {
        new Vue({
            el: "#customerSelect",
            data: {
                options: [],
                customerAccount: "",
            },
            mounted: function () {
                if (document.getElementById("customerObj") != null) {
                    this.customerAccount = JSON.parse(
                        document.getElementById("customerObj").dataset.customer
                    );
                }
            },
            methods: {
                fetchOptions: function (search, loading) {
                    loading(true);

                    axios
                        .get(
                            "/overseers/customer_accounts/autocomplete.json?" +
                            "query=" +
                            search
                        )
                        .then((response) => {
                            console.log(response);

                            this.options = response.data.results;
                            loading(false);
                        });
                },
                search: (loading, search, vm) => {
                },
            },
        });
    }
});
